import React from 'react';
import { CustomTable, CustomTransComponent } from '../../components';
import { Box, Chip, IconButton, Paper, Stack, Switch, TextField } from '@mui/material';
import { Header } from '@fiji/common/src/types';
import LooksOne from '@mui/icons-material/LooksOne';
import { useGetAllLoadsQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useTheme } from '@mui/material/styles';
import {
    setLoadModal,
    setPayload,
    setSearchKey,
    resetFilters,
    resetAllFilters,
    selectLoadPayload,
    selectSearchKey,
} from '@fiji/common/src/features/Loads/loadSlice';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useDebounce, useIsMount } from '@fiji/common/src/hooks';
import { useDispatch } from 'react-redux';
import Search from '@mui/icons-material/Search';
import { usePayloadActions } from '@fiji/common/src/hooks/usePayloadActions';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';

const getModifiedPayload = (filters: any): any => {
    const clonefilters = JSON?.parse(JSON.stringify(filters ?? {}));
    if (clonefilters?.isLoadEnabled) {
        if (clonefilters?.isLoadEnabled?.length > 1) {
            delete clonefilters['isLoadEnabled'];
        } else {
            clonefilters['isLoadEnabled'] = clonefilters['isLoadEnabled'][0];
        }
    }
    return clonefilters;
};

const ActionCell = (data: any, currentRealm: any, deviceId: any, navigate: any): JSX.Element => (
    <IconButton
        id="details"
        onClick={(): void => {
            navigate(`/${currentRealm}/loadDetails/${deviceId}/${data?.loadId}`);
        }}
    >
        <ChevronRight />
    </IconButton>
);

const getLoadNumber = (data: any): JSX.Element => (
    <Box className="f-14 fw-600 bg-content text-white width-18 border-radius-4 flex-center-align">
        {parseInt(data?.['loadNumber'] ?? 0) + 1}
    </Box>
);

const getIsLoadEnabled = (
    data: any,
    handleSwitch: any,
    loads: any,
    theme: any,
    canUpdateLoads: boolean
): JSX.Element => (
    <Switch
        onClick={(): void => handleSwitch(data)}
        checked={data?.isLoadEnabled}
        disabled={!data?.isLoadEnabledInMap || loads?.data?.offlineDevice || !canUpdateLoads}
        color="primary"
        sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
                color: theme?.palette?.primary?.main,
                '& + .MuiSwitch-track': {
                    backgroundColor: theme?.palette?.primary?.main,
                },
            },
            '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                color: theme?.palette?.primary?.[50],
                '& + .MuiSwitch-track': {
                    backgroundColor: theme?.palette?.primary?.[200],
                },
            },
        }}
    />
);

export const Loads = (): JSX.Element => {
    const theme: any = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMount = useIsMount();
    const navigate = useNavigate();
    const { deviceId } = useParams();
    const tableRef: any = React.useRef(null);
    const searchKey = useTypedSelector(selectSearchKey);
    const payload = useTypedSelector(selectLoadPayload);
    const currentRealm = useTypedSelector((state) => state.common.selectedRealm);
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const [cleanPayload] = usePayloadActions();

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateLoads = hasPermission('edit-devices');

    const {
        data: loads,
        isLoading,
        isFetching,
    } = useGetAllLoadsQuery(
        { deviceId, body: cleanPayload({ ...payload, filters: getModifiedPayload(payload?.filters ?? {}) }) },
        { refetchOnMountOrArgChange: true, skip: !deviceId }
    );

    const handlePageChange = (page: any, size: any): void => {
        dispatch(setPayload({ page: page, size: size }));
    };

    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
            dispatch(
                setPayload({
                    ...payload,
                    filters,
                })
            );
        }
        if (sortData && Object.keys(sortData)?.length) {
            dispatch(
                setPayload({
                    ...payload,
                    sort: sortData,
                })
            );
        }
    };

    const handleSwitch = (data: any): void => {
        if (data?.isLoadEnabled) {
            dispatch(
                setLoadModal({
                    isOpen: true,
                    command: 'TURN_OFF',
                    details: data,
                })
            );
            return;
        }
        dispatch(
            setLoadModal({
                isOpen: true,
                command: 'TURN_ON',
                details: data,
            })
        );
    };
    React.useEffect(() => {
        if (!isMount) {
            dispatch(
                setPayload({
                    ...payload,
                    searchKey: debouncedValue,
                })
            );
        }
    }, [debouncedValue]);

    React.useEffect(
        () => () => {
            dispatch(resetAllFilters());
        },
        []
    );

    const tableRows: Header[] = [
        {
            header: `${t('HEALTH_STATUS:LOAD')}  #`,
            isSortable: true,
            width: '15%',
            accessor: 'loadNumber',
            cell: (data: any): JSX.Element => getLoadNumber(data),
        },
        {
            header: t('COMMON:NAME'),
            width: '20%',
            accessor: 'loadName',
        },
        {
            header: t('COMMON:STATE'),
            width: '15%',
            accessor: 'isLoadEnabled',
            cell: (data: any): JSX.Element => getIsLoadEnabled(data, handleSwitch, loads, theme, canUpdateLoads),
            isFilterable: true,
            filterOptions: [
                { id: 'true', label: 'Active' },
                { id: 'false', label: 'Disabled' },
            ],
        },
        {
            width: '45%',
            header: (<CustomTransComponent translationKey={'ALARM_DETAILS:DESCRIPTION_TITLE'} />) as any,
            accessor: 'loadDescription',
        },
        {
            header: '',
            width: '5%',
            cell: (data: any) => ActionCell(data, currentRealm, deviceId, navigate),
        },
    ];

    const handleChipDelete = (key: any): void => {
        tableRef?.current?.resetFilters(key === 'groupId' ? 'groupPath' : key, true);
        dispatch(resetFilters(key));
    };

    return (
        <Stack p={3}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                <TextField
                    hiddenLabel
                    id="searchKey"
                    value={searchKey ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        dispatch(setSearchKey(e.target.value));
                    }}
                    placeholder={t('COMMON:SEARCH_PLACEHOLDER')}
                    size="small"
                    InputProps={{
                        startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                    }}
                />

                {payload?.filters && (
                    <Stack direction="row" spacing={1}>
                        {Object.keys(payload?.filters).map(
                            (key: string) =>
                                key !== 'deviceId' && (
                                    <Chip
                                        key={`unique${key}`}
                                        label="Enabled"
                                        onDelete={(): void => {
                                            handleChipDelete(key);
                                        }}
                                    />
                                )
                        )}
                    </Stack>
                )}
            </Stack>

            <Paper className="margin-y-16 box-shadow">
                <CustomTable
                    total={loads?.data?.total ?? 10}
                    data={loads?.data?.loadConfiguration}
                    ref={tableRef}
                    isPagination
                    // containerClass="custom-data-table"
                    isLoading={isFetching || isLoading}
                    keyToTraverse="id"
                    headers={tableRows}
                    noDataFoundIcon={<LooksOne fontSize="inherit" />}
                    noDataFoundTitle={<CustomTransComponent translationKey={'WIDGETS:LOADS.NO_LOADS_FOUND'} />}
                    noDataFoundButtonText="Add Loads"
                    handlePageChange={handlePageChange}
                    handleFilterChange={handleFilterChange}
                    // noDataFoundButtonAction={(): void => navigate(`/${currentRealmName}/addOrEditUser`)}
                />
            </Paper>
        </Stack>
    );
};
