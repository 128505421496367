import React from 'react';
import { CustomModal, ModalActions } from './CustomModal';
import { Radio, Stack, Typography } from '@mui/material';
import { useCreateSnoozeMutation } from '@fiji/common/src/features/Timeline/timelineApi';
import { useConfirm } from '@fiji/common/src/hooks';
import { useDispatch } from 'react-redux';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useTranslation } from 'react-i18next';
import { CustomTransComponent } from './CustomTransComponent';
import { useNewCustomTranslations } from 'hooks';

const CreateSnoozeModal = React.forwardRef((props: any, ref): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useNewCustomTranslations('DEVICE_MANAGEMENT');
    const [duration, setDuration] = React.useState(0);
    const [createSnooze, { isLoading: isSnoozeCreating }] = useCreateSnoozeMutation();
    const submitHandler = async (isSnoozed: boolean): Promise<void> => {
        const { error }: any = await createSnooze({
            deviceId: props?.deviceId,
            isSnoozed,
            duration: duration * 60 * 1000,
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: isSnoozed
                        ? t('DEVICE_MANAGEMENT:DEVICE_SNOOZED_SUCCESS_MSG')
                        : t('DEVICE_MANAGEMENT:DEVICE_UNSNOOZED_SUCCESS_MSG'),
                })
            );
            onCancel();
            setDuration(0);
        }
    };
    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(submitHandler as any);
    React.useImperativeHandle(ref, () => ({
        onClick: (action: boolean) => onClick(action),
        submitHandler: (action: boolean) => submitHandler(action) as any,
    }));
    const SnoozeActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
                setDuration(0);
            },
        },
        {
            key: 'apply',
            label: <CustomTransComponent translationKey={'COMMON:APPLY'} />,
            disabled: !duration,
            variant: 'contained',
            handleClick: onConfirm,
        },
    ];
    return (
        <CustomModal
            isOpen={isVisible}
            isLoading={isSnoozeCreating}
            type="primary"
            header="Snooze Notifications"
            subHeader="Temporarily suppresses notifications."
            actions={SnoozeActions}
        >
            <>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Radio checked={duration === 30} onChange={(): void => setDuration(30)} />{' '}
                    <Typography variant="body1">30 Minutes</Typography>
                </Stack>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Radio id="snooze-radio" checked={duration === 60} onChange={(): void => setDuration(60)} />{' '}
                    <Typography variant="body1">1 Hour</Typography>
                </Stack>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Radio checked={duration === 240} onChange={(): void => setDuration(240)} />{' '}
                    <Typography variant="body1">4 Hours</Typography>
                </Stack>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Radio checked={duration === 1440} onChange={(): void => setDuration(1440)} />{' '}
                    <Typography variant="body1">24 Hours</Typography>
                </Stack>
            </>
        </CustomModal>
    );
});

export default CreateSnoozeModal;
