// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react';

import {
    useGetAllDeviceCategoriesMutation,
    useGetDeviceModelsByCategoryMutation,
} from '../features/deviceManagement/ontologyApi';

type ReturnProps = {
    isLoading: boolean;
    loadingNode: any;
    fetchHierachy: any;
    handleCleanNode: any;
    deviceCategories: any;
    hierarchyMappedData: any;
    hasChild: (args: any) => boolean;
};

export const useHandleDeviceTypesHierachy = (): ReturnProps => {
    const [loadingNode, setLoadingNode] = React.useState([]);
    const [deviceCategories, setDeviceCategories] = React.useState([]);
    const [hierarchyMappedData, setHierarchyMappedData] = React.useState({});

    const [getDeviceModels] = useGetDeviceModelsByCategoryMutation();
    const [getAllDeviceCategories, { data, isLoading }] = useGetAllDeviceCategoriesMutation();

    React.useEffect(() => {
        if (!deviceCategories?.length) {
            setDeviceCategories(data?.data);
        }
    }, [data]);

    React.useEffect(() => {
        void getAllDeviceCategories({ category: 'CATEGORY' });
    }, []);

    const hasChild = (treeItem: any): boolean => treeItem?.type !== 'MODEL';

    const handleCleanNode = (parentId: string): void => {
        setHierarchyMappedData((prevState: any) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    const fetchHierachy = async (currentNode: any, filter?: any): Promise<void> => {
        setLoadingNode((prevState): any => [...prevState, currentNode?.id]);
        const { data: deviceModels }: any = await getDeviceModels({
            filter: currentNode?.id,
            ...(filter && {
                searchKey: filter?.searchKey,
                searchValue: filter?.searchValue,
            }),
        });
        if (deviceModels) {
            setHierarchyMappedData((prev: any) => ({
                ...prev,
                [currentNode?.id]: deviceModels?.data?.map((item: any) => ({
                    id: currentNode?.id,
                    type: 'MODEL',
                    ...item,
                })),
            }));
        }

        setLoadingNode((prevState): any => prevState.filter((node: any) => node !== currentNode?.id));
    };

    return {
        hasChild,
        isLoading,
        loadingNode,
        fetchHierachy,
        handleCleanNode,
        deviceCategories,
        hierarchyMappedData,
    };
};
