/* eslint-disable @typescript-eslint/no-this-alias */
import React, { useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { WidgetFooter } from 'pages/WidgetManagement/common/WidgetFooter';
import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import WidgetNotConfigured from 'pages/EditWidget/WIdgetNotConfigured';
import { areAllDataArraysEmpty, getTimeZoneOffset } from 'utils/helpers';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { HeaderAction } from '../aggregated_trends/HeaderAction';

export type TelemetryChartProps = {
    type?: 'area' | 'line';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount?: number;
    secondary: any[];
    widgetName?: string;
    timeZone?: any;
    [key: string]: any;
};

// Extend dayjs with utc and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const trendLineColors = ['#007bc1', '#f0aa1f'];

const Component = React.forwardRef(
    ({
        widgetData,
        mode,
        widgetFilter,
        downloadCSVHandler,
    }: {
        widgetData: TelemetryChartProps;
        mode?: string;
        widgetFilter?: any;
        downloadCSVHandler?: any;
    }): any => {
        const chartRef: any = useRef(null);
        const handleTooltipDateFormat = (): string => {
            switch (widgetData?.selectedTimePeriod) {
                case 'today':
                    return '%H:%M';
                case 'week':
                    return '%A, %H:%M';
                case 'month':
                    return '%e, %H:%M';
                case 'year':
                    return '%b, %e, %H:%M';
                default:
                    return '%H:%M';
            }
        };

        const getLabelFormat = (): string => {
            switch (widgetData?.selectedTimePeriod) {
                case 'today':
                    return '%H:%M';
                case 'week':
                    return '%A';
                case 'month':
                    return '%e';
                case 'year':
                    return '%b';
                default:
                    return '%H:%M';
            }
        };

        const getTickInterval = (): number => {
            switch (widgetData?.selectedTimePeriod) {
                case 'today':
                    return 4 * 3600 * 1000;
                case 'week':
                    return 24 * 3600 * 1000;
                case 'month':
                    return 24 * 3600 * 1000;
                case 'year':
                    return 30 * 24 * 3600 * 1000;
                default:
                    return 4 * 3600 * 1000;
            }
        };

        const [options, setOptions] = useState<any>({
            chart: {
                type: 'spline',
                zoomType: 'x',
                zooming: {
                    mouseWheel: {
                        enabled: false,
                        sensitivity: 1.1,
                        type: 'x',
                    },
                },
            },
            title: {
                text: ' ',
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                shared: true,
                style: {
                    fontSize: '',
                },
                followTouchMove: false,
                outside: true,
            },
            xAxis: {
                type: 'datetime',
                crosshair: {
                    color: 'gray',
                    dashStyle: 'Dash',
                    width: 1,
                },
                labels: {
                    format: `{value:${getLabelFormat()}}`,
                },
                plotLines: [
                    {
                        color: '#515851',
                        width: 2,
                        // value: new Date().getTime() - new Date().getTimezoneOffset() * 60000,
                        label: {
                            useHTML: true,
                            text: 'CURRENT TIME',
                            align: 'left',
                            rotation: 0,
                            x: -65,
                            style: {
                                color: '#fffbef',
                                backgroundColor: '#515851',
                                height: '25px',
                                borderRadius: '20px',
                                textAlign: 'center',
                                fontWeight: '530',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                paddingTop: '5px',
                                paddingBottom: '25px',
                            },
                        },
                    },
                ],
                zoomEnabled: true,
                panningEnabled: true,
            },
            legend: {
                enabled: true,
            },
            series: [],
            plotOptions: {
                series: {
                    marker: {
                        symbol: 'circle',
                    },
                },
            },
            exporting: {
                enabled: false,
            },
        });

        const getSeriesData = (data: any): any[] =>
            data?.map((trend: any, index: number) => ({
                id: trend?.id ?? trend?.channelId,
                name: trend?.displayName,
                data: trend?.data?.map((point: any): any => [point?.x, point?.y]),
                color: trendLineColors[index],
                type: widgetData?.type ?? 'line',
                tooltip: {
                    valueSuffix: trend?.unit ? ` ${trend?.unit}` : '%',
                },
            }));

        const getUnit = (axis: any): string => {
            let unit = '';
            const current: any = chartRef?.current;
            current?.chart?.series.forEach((series: any): void => {
                if (series?.dataMax === axis?.axis?.dataMax) {
                    unit = series?.tooltipOptions?.valueSuffix;
                }
            });
            return unit;
        };

        const getYAxis = (): any => ({
            title: {
                text: '',
            },
            labels: {
                formatter: function (): any {
                    const yAxis: any = this;
                    return `${yAxis?.value}${getUnit(this)}`;
                },
            },
            minPadding: 0.5,
            maxPadding: 0.5,
        });

        const getMonthBuffer = (): number => {
            const localizedDateString = new Date().toLocaleDateString();
            const parsedDate = new Date(localizedDateString);
            const month = parsedDate.getMonth();
            const year = parsedDate.getFullYear();
            const lastDayOfPreviousMonth = new Date(year, month, 0);
            const numberOfDays = lastDayOfPreviousMonth.getDate();
            return numberOfDays * 86400000;
        };

        const getYearBuffer = (): number => {
            const localizedDateString = new Date().toLocaleDateString();
            const parsedDate = new Date(localizedDateString);
            const year = parsedDate.getFullYear();
            if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
                // check for leap year
                return 366 * 86400000;
            }
            return 365 * 86400000;
        };

        const getTimeBuffer = (selectedTime: string | undefined): number => {
            // number of milliseconds for 1 day='86400000'
            switch (selectedTime) {
                // for today, comparison is being made between current day and same day of the past week
                case 'today':
                case 'week':
                    return 86400000 * 7;
                case 'month':
                    return getMonthBuffer();
                case 'year':
                    return getYearBuffer();
                default:
                    return 86400000;
            }
        };

        const getTimeAlteredData = (data: any): any[] => {
            const timezoneOffsetMs = getTimeZoneOffset(widgetData?.timeZone);
            const timeBuffer = getTimeBuffer(widgetData?.selectedTimePeriod);

            const timeAdjustedData = data?.map((dataset: any) => {
                const modifiedData = dataset?.data?.map((point: any) => {
                    const localTimestamp =
                        point.x - timezoneOffsetMs + (dataset?.displayName === 'Previous' ? timeBuffer : 0);
                    return {
                        x: localTimestamp,
                        y: point?.y,
                    };
                });

                return {
                    ...dataset,
                    data: modifiedData,
                };
            });
            return timeAdjustedData || [];
        };

        const getAbsentDataTitle = (): string | undefined => {
            if (widgetData?.multiDevice && widgetData?.assets?.length === 0) {
                return 'Widget Not Configured';
            } else if (
                (areAllDataArraysEmpty(widgetData?.secondary) && !widgetData?.multiDevice) ||
                widgetData?.assets?.length
            ) {
                return 'No Data Found';
            }
            return undefined;
        };

        const handleFullScreen = (): React.JSX.Element =>
            widgetData?.secondary?.length ? (
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            ) : (
                <WidgetNotConfigured title={getAbsentDataTitle()} />
            );

        React.useEffect(() => {
            const chart = chartRef?.current?.chart;
            const newOption = JSON.parse(JSON.stringify(options));
            if (chart) {
                chart.zoomOut();
            }
            if (chart && widgetData?.secondary?.length) {
                const data = getTimeAlteredData(widgetData?.secondary);
                newOption['yAxis'] = getYAxis();
                newOption['series'] = getSeriesData(data);
                newOption['chart'] = {
                    type: widgetData?.type ?? 'line',
                    zoomType: 'x',
                    zooming: {
                        mouseWheel: {
                            enabled: true,
                            sensitivity: 1.1,
                            type: 'x',
                        },
                    },
                };
                newOption['xAxis']['tickInterval'] = getTickInterval();
                newOption['xAxis']['plotLines'][0]['value'] =
                    new Date().getTime() - getTimeZoneOffset(widgetData?.timeZone);
                newOption['tooltip']['xDateFormat'] = handleTooltipDateFormat();
                newOption['xAxis']['labels']['format'] = `{value:${getLabelFormat()}}`;
                if (widgetData?.selectedTimePeriod === 'year') {
                    newOption['xAxis']['min'] = dayjs().startOf('year')?.valueOf();
                    newOption['xAxis']['max'] = dayjs().endOf('year')?.valueOf();
                } else if (widgetData?.selectedTimePeriod === 'month') {
                    newOption['xAxis']['min'] = dayjs().startOf('month')?.valueOf();
                    newOption['xAxis']['max'] = dayjs().endOf('month')?.valueOf();
                } else if (widgetData?.selectedTimePeriod === 'week') {
                    newOption['xAxis']['min'] = dayjs().startOf('week')?.valueOf();
                    newOption['xAxis']['max'] = dayjs().endOf('week')?.valueOf();
                } else {
                    newOption['xAxis']['min'] = null;
                    newOption['xAxis']['max'] = null;
                }
                setOptions(newOption);
                chart?.hideLoading();
            } else if (chart) {
                newOption['series'] = [];
                setOptions(newOption);
                chart?.showLoading('No Trends Data Available');
            }
        }, [widgetData]);

        return (
            <Stack sx={{ height: '100%' }} className={mode !== 'viewAll' ? 'bg-white box-shadow' : 'bg-white'}>
                {(widgetData?.widgetTypeId !== 'load_trends' || widgetData?.viewTitleBar) && mode !== 'viewAll' && (
                    <WidgetHeader
                        mode={mode}
                        widgetData={widgetData}
                        widgetFilter={widgetFilter}
                        {...((widgetData?.viewTimePeriod || widgetData?.widgetTypeId === 'aggregated_trends') && {
                            headerAction: (
                                <HeaderAction
                                    mode={mode}
                                    widgetData={widgetData}
                                    widgetFilter={widgetFilter}
                                    downloadCSVHandler={downloadCSVHandler}
                                />
                            ),
                        })}
                    />
                )}
                {mode !== 'edit' && <Divider />}
                {mode === 'viewAll' ? (
                    handleFullScreen()
                ) : (
                    <Stack p={2} sx={{ height: 'calc(100% - 91px)', overflow: 'auto' }}>
                        {!areAllDataArraysEmpty(widgetData?.secondary) ? (
                            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
                        ) : (
                            <WidgetNotConfigured title={getAbsentDataTitle()} />
                        )}
                    </Stack>
                )}
                {(widgetData?.widgetTypeId !== 'load_trends' || widgetData?.viewAllButton) && mode !== 'viewAll' && (
                    <WidgetFooter
                        mode={mode ?? ''}
                        ids={[]}
                        widgetData={widgetData}
                        disabled={!widgetData?.secondary?.length}
                    />
                )}
            </Stack>
        );
    }
);
export default Component;
