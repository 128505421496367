import { api } from '../../app/api/baseApi';
import { CACHE_TIMEOUT_DURATION } from '../../constants/cacheTimeout';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';

const deviceApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getConsumptionBreakdownData: builder.query({
            query: (body) => ({
                url: 'ems/v1/consumption-breakdown',
                method: 'POST',
                body,
            }),
        }),
        getLoadTrendsData: builder.query({
            query: (body) => ({
                url: 'ems/v1/load-trend',
                method: 'POST',
                body,
            }),
        }),
        getMetricsWidgetData: builder.query({
            query: (body) => ({
                url: 'ems/v1/efficiency',
                method: 'POST',
                body,
            }),
        }),
        getAssetsListData: builder.query({
            query: (widgetTypeId) => ({
                url: 'ems/v1/asset',
                method: 'GET',
                params: { widgetTypeId },
            }),
            keepUnusedDataFor: CACHE_TIMEOUT_DURATION,
        }),
        getEnergyFLowAssetsData: builder.query({
            query: (body) => ({
                url: `ems/v1/energy/flow`,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: CACHE_TIMEOUT_DURATION,
        }),
        getConsumptionBreakdownAssets: builder.query({
            query: () => ({
                url: 'ems/v1/asset-list/cbd',
                method: 'GET',
            }),
        }),
        getAggregatedBarChartData: builder.query({
            query: (body) => ({
                url: 'ems/v1/chart/bar',
                method: 'POST',
                body,
            }),
        }),
        getAggregatedLineChartData: builder.query({
            query: (body) => ({
                url: 'ems/v1/chart/line',
                method: 'POST',
                body,
            }),
        }),
        getWeatherData: builder.query({
            query: (body) => ({
                url: 'ems/v1/weather-forecast',
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: CACHE_TIMEOUT_DURATION,
        }),
        getAllSchedulesData: builder.query({
            query: (body) => ({
                url: 'automation/v1/schedule/summary',
                method: 'POST',
                body,
            }),
        }),
        getAssetSchedule: builder.query({
            query: (body) => ({
                url: 'automation/v1/schedule/list/ungrouped',
                method: 'POST',
                body,
            }),
        }),
        getAssetTypeSchedule: builder.query({
            query: (body) => ({
                url: 'automation/v1/schedule/list/grouped',
                method: 'POST',
                body,
            }),
        }),
        getAllAssetTypesDropdown: builder.query({
            query: (body) => ({
                url: 'automation/v1/schedule/allAssetTypes',
                method: 'POST',
                body,
            }),
        }),
        getAllActionTypesDropdown: builder.query({
            query: (body) => ({
                url: 'automation/v1/schedule/allActionTypes',
                method: 'POST',
                body,
            }),
        }),
        getCalendarSchedule: builder.query({
            query: (body) => ({
                url: 'automation/v1/schedule/list/week-view',
                method: 'POST',
                body,
            }),
        }),
        getAllSchedulesList: builder.query({
            query: (body) => ({
                url: 'automation/v1/schedule/filters',
                method: 'POST',
                body,
            }),
        }),
        getAllDevices: builder.query({
            query: (body) => {
                const bodyClone = JSON.parse(JSON.stringify(body));
                if (bodyClone.headers) {
                    delete bodyClone.headers;
                }
                return {
                    url: 'dm/v1/devices/filter',
                    method: 'POST',
                    body: bodyClone,
                    ...(body.headers && { headers: body.headers }),
                };
            },
            keepUnusedDataFor: 0.000001,
            providesTags: ['DeviceList'],
        }),
        getAllSubscriptionDevices: builder.query({
            query: ({ body, params }) => {
                const bodyClone = JSON.parse(JSON.stringify(body));
                if (bodyClone.headers) {
                    delete bodyClone.headers;
                }
                return {
                    url: 'dm/v1/devices/filter',
                    method: 'POST',
                    ...(params && { params }),
                    body: bodyClone,
                    ...(body.headers && { headers: body.headers }),
                };
            },
            keepUnusedDataFor: 0.000001,
            providesTags: ['DeviceList'],
        }),
        getDeviceList: builder.mutation({
            query: ({ body }: any) => ({
                url: 'dm/v1/devices/filter',
                method: 'POST',
                body,
            }),
        }),
        getAllDeviceStatus: builder.query({
            query: () => ({
                url: 'dm/v1/devices/status',
            }),
        }),
        getDeviceTable: builder.query({
            query: (tableType) => ({
                url: 'iam/v1/users/me/ui/config',
                params: { tableType },
            }),
        }),
        getChannelDetailsWithCategory: builder.query({
            query: ({ deviceId, params }) => ({
                url: `dm/v1/devices/${deviceId}/channels/category`,
                params,
            }),
        }),
        updateDeviceTable: builder.mutation({
            query: ({ body, tableType }: any) => ({
                url: 'iam/v1/users/me/ui/config',
                method: 'PUT',
                params: { tableType: tableType },
                body,
            }),
        }),
        getMobileDevices: builder.query<any, any>({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: 'dm/v1/devices/filter',
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
            providesTags: ['DeviceList'],
        }),
        getDeviceByProfile: builder.query({
            query: (sourceId) => ({
                url: `dm/v1/device/types/device/${sourceId}`,
            }),
        }),

        //will be removed once master data api is used for retrieving device types

        getAllDeviceTypes: builder.query({
            query: (args) => ({
                url: 'dm/v1/device/types/filter',
                method: 'POST',
                ...(args?.body && { body: args?.body }),
                ...(args?.parent && { params: { parent: args?.parent } }),
            }),
        }),

        getAllChannels: builder.query({
            query: ({ sourceId, params, body }: any) => ({
                url: `dm/v1/device/types/${sourceId}/channels`,
                params,
                method: 'GET',
                ...(body && { body }),
            }),
            transformResponse: (response: any, _meta: any, args: any) => {
                const responseClone = JSON.parse(JSON.stringify(response));
                if (args?.number) {
                    responseClone.data = responseClone.data.filter((record: any) => record.dataType === 'Number');
                }
                return responseClone;
            },
        }),

        getSearchedGatewayOrGroup: builder.query({
            query: ({ search, headers }: any) => ({
                url: `ems/v1/assets/hierarchy`,
                ...(search && { params: { search } }),
                method: 'GET',
                ...(headers && { headers }),
            }),
        }),

        getChannelUnits: builder.query({
            query: (body: any) => ({
                url: `dm/v1/device/types/units`,
                body,
                method: 'POST',
            }),
        }),
        getDeviceAlertCount: builder.query({
            query: (body: any) => ({
                url: `alarm/v1/count`,
                body,
                method: 'POST',
            }),
        }),
        getDeviceById: builder.query({
            query: (deviceId) => ({
                url: `dm/v1/devices/${deviceId}`,
            }),
            providesTags: ['DeviceById'],
        }),
        getDeviceTimeline: builder.query({
            query: ({ body, params }: any) => ({ url: `alarm/v1/timeline/filter`, method: 'POST', body, params }),
        }),

        getMobileDeviceTimeline: builder.query({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: `alarm/v1/timeline/filter`,
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
        }),

        getChannelDetails: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/devices/details',
                method: 'POST',
                body,
            }),
        }),
        getDeviceTrends: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/devices/trends',
                method: 'POST',
                body,
            }),
        }),
        getUIMetadata: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/device/types/meta/data',
                method: 'POST',
                body,
            }),
        }),
        getMasterData: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/device/types/master/data',
                method: 'POST',
                body,
            }),
        }),
        createDevice: builder.mutation({
            query: (body) => ({
                url: `/dm/v1/devices`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['DeviceList', 'GroupsHierarchy', 'Orgs'] : []),
            extraOptions: 'no-toast',
        }),
        editDevice: builder.mutation<object, any>({
            query: (body) => ({
                url: `/dm/v1/devices/${body.id}`,
                method: 'PATCH',
                body: body.payload,
            }),
            invalidatesTags: (result, error): any => (!error ? ['GroupsHierarchy', 'DeviceList'] : []),
        }),
        getChannelThresholds: builder.query({
            query: ({ channelId, deviceId }: any) => ({
                url: `dm/v1/device-threshold/${deviceId}/${channelId}`,
            }),
        }),
        getSearchedDeviceOrGroup: builder.query({
            query: (body): any => ({
                body,
                method: 'POST',
                url: `iam/v1/groups/hierarchy/search`,
            }),
        }),
        generateDeviceName: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/devices/valid/name',
                method: 'POST',
                body,
            }),
        }),
        getThreshold: builder.query({
            query: ({ channelKey, deviceId }: any) => ({
                url: `dm/v1/device-threshold/${deviceId}/${channelKey}`,
            }),
            keepUnusedDataFor: 0.1,
            providesTags: ['Threshold'],
        }),
        updateThreshold: builder.mutation({
            query: ({ channelKey, deviceId, body }) => ({
                url: `dm/v1/device-threshold/${deviceId}/${channelKey}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Threshold'] : []),
        }),
        getSettings: builder.query({
            query: ({ deviceId }) => ({
                url: `dm/v1/devices/${deviceId}/device-settings`,
            }),
            providesTags: ['device-settings'],
        }),
        updateSettings: builder.mutation({
            query: ({ body, deviceId }) => ({
                url: `dm/v1/device-settings/${deviceId}/command`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['device-settings'] : []),
        }),
        sendDeviceCommand: builder.mutation({
            query: ({ body, deviceId }) => ({
                url: `dm/v1/devices/${deviceId}/command`,
                method: 'POST',
                body,
            }),
        }),

        getDeviceSettings: builder.mutation({
            query: (body) => ({
                url: `dm/v1/device-settings`,
                method: 'POST',
                body,
            }),
        }),
        updateDevice: builder.mutation({
            query: ({ deviceId, body }) => ({
                url: `dm/v1/devices/${deviceId}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Properties', 'DeviceList', 'DeviceById'] : []),
        }),
        deleteDevice: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/devices',
                body,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['DeviceList'] : []),
        }),
        getAllLoads: builder.query({
            query: ({ deviceId, body }) => {
                return {
                    url: `dm/v1/device-loads/${deviceId}`,
                    method: 'POST',
                    body: body ?? { page: 0, size: 10 },
                };
            },
            providesTags: ['AllLoads'],
        }),
        getMultiDeviceLoads: builder.query({
            query: (body) => ({
                url: `dm/v1/device-loads/all/loads`,
                method: 'POST',
                body: body,
            }),
            providesTags: ['MultiLoads'],
        }),
        getAllVirtualLoads: builder.query({
            query: (body) => ({
                url: 'dm/v1/connected-loads/filter',
                method: 'POST',
                body,
            }),

            providesTags: ['Virtual_Loads'],
        }),
        getMobileVirtualLoads: builder.query({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: 'dm/v1/connected-loads/filter',
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
            providesTags: ['Virtual_Loads'],
        }),
        getAllVirtualLoadTypes: builder.query<any, void>({
            query: () => ({
                url: 'dm/v1/connected-loads/types',
            }),
        }),

        createVirtualLoad: builder.mutation({
            query: (body) => ({
                url: `dm/v1/connected-loads`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Virtual_Loads'] : []),
        }),
        getDetailsOfLoadById: builder.query({
            query: ({ id, loadId }) => ({
                url: `dm/v1/device-loads/${id}/${loadId}`,
                method: 'GET',
            }),
            providesTags: ['LoadDetails'],
        }),
        getDeviceProperties: builder.query({
            query: ({ deviceId, organizationId }) => {
                return {
                    url: `dm/v1/devices/${deviceId}/properties`,
                    ...(organizationId && { headers: { organizationId } }),
                };
            },
            providesTags: ['Properties'],
        }),
        updateStatusOfLoad: builder.mutation({
            query: ({ id, loadId, command }) => {
                return {
                    url: `dm/v1/device-loads/${id}/${loadId}/command`,
                    method: 'PATCH',
                    body: {
                        command,
                    },
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['AllLoads', 'LoadDetails', 'MultiLoads'] : []),
        }),
        getAvailableCommands: builder.query({
            query: (body) => {
                return {
                    url: `dm/v1/devices/available/controls`,
                    method: 'POST',
                    body: {
                        controlRequestSet: body,
                    },
                };
            },
        }),
        updateLoadDetails: builder.mutation({
            query: ({ id, loadId, body }) => ({
                url: `dm/v1/device-loads/${id}/${loadId}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['LoadDetails', 'AllLoads', 'MultiLoads'] : []),
        }),
        getLoadList: builder.query({
            query: (body: any) => ({
                url: '/dm/v1/connected-loads/filter',
                method: 'POST',
                body,
            }),
            ...PAGINATION_PAYLOAD,
        }),
        exportTrends: builder.mutation({
            query: ({ body, params }: any) => ({
                url: 'dm/v1/devices/export',
                method: 'POST',
                body,
                params,
            }),
        }),
        deviceMap: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/devices/map',
                method: 'POST',
                body,
            }),
        }),
        getVirtualLoadById: builder.query({
            query: (connectedLoadId) => ({
                url: `dm/v1/connected-loads/${connectedLoadId}`,
                method: 'GET',
            }),
            providesTags: ['VirtualLoadDetails'],
        }),
        deleteVirtualLoadById: builder.mutation({
            query: (connectedLoadId) => ({
                url: `dm/v1/connected-loads/${connectedLoadId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Virtual_Loads'] : []),
        }),
        updateVirtualLoadById: builder.mutation({
            query: ({ connectedLoadId, body }: any) => ({
                url: `dm/v1/connected-loads/${connectedLoadId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Virtual_Loads', 'VirtualLoadDetails'] : []),
        }),
        updateDeviceConfig: builder.mutation({
            query: ({ deviceId, body }) => ({
                url: `dm/v1/devices/${deviceId}`,
                method: 'PUT',
                body: { payload: body.payload },
            }),
            invalidatesTags: (result, error): any => (!error ? ['Properties', 'DeviceList'] : []),
        }),
        getDeviceStatus: builder.query({
            query: () => ({
                url: `dm/v1/devices/status`,
                method: 'GET',
            }),
        }),
        getModelsData: builder.query({
            query: (category) => ({
                url: 'ontology/api/v1/models/category',
                method: 'GET',
                params: { category },
            }),
        }),
        getComponentFilter: builder.query({
            query: (body) => ({
                url: '/ds/v1/demo/filter',
                method: 'POST',
                body,
            }),
        }),
        createSchedule: builder.mutation({
            query: (body) => ({
                url: `automation/v1/schedule`,
                method: 'POST',
                body,
            }),
            // invalidatesTags: (result, error): any => (!error ? ['Properties', 'DeviceList'] : []),
        }),
        getScheduleDetails: builder.query({
            query: ({ id, time }) => ({
                url: `automation/v1/schedule/pop-up/${id}?date=${time}`,
                method: 'GET',
            }),
        }),
        downloadChartData: builder.mutation({
            query: (body) => ({
                url: 'ems/v1/chart/download',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetConsumptionBreakdownDataQuery,
    useGetLoadTrendsDataQuery,
    useLazyGetEnergyFLowAssetsDataQuery,
    useLazyGetMetricsWidgetDataQuery,
    useGetAssetsListDataQuery,
    useGetConsumptionBreakdownAssetsQuery,
    useGetMetricsWidgetDataQuery,
    useGetEnergyFLowAssetsDataQuery,
    useLazyGetAggregatedBarChartDataQuery,
    useLazyGetAggregatedLineChartDataQuery,
    useLazyGetWeatherDataQuery,
    useGetModelsDataQuery,
    useLazyGetAllSchedulesDataQuery,
    useLazyGetAssetScheduleQuery,
    useLazyGetAssetTypeScheduleQuery,
    useLazyGetAllAssetTypesDropdownQuery,
    useLazyGetAllActionTypesDropdownQuery,
    useLazyGetCalendarScheduleQuery,
    useLazyGetAllSchedulesListQuery,
    useGetAllSubscriptionDevicesQuery,
    useGetAllDevicesQuery,
    useDeviceMapMutation,
    useGetDeviceSettingsMutation,
    useLazyGetConsumptionBreakdownDataQuery,
    useGetAllLoadsQuery,
    useLazyGetAllLoadsQuery,
    useGetAllDeviceStatusQuery,
    useUpdateSettingsMutation,
    useGetSearchedDeviceOrGroupQuery,
    useGetChannelUnitsQuery,
    useGetMultiDeviceLoadsQuery,
    useGetSettingsQuery,
    useGetMobileDevicesQuery,
    useGetAllDeviceTypesQuery,
    useGetAllVirtualLoadsQuery,
    useGetAllVirtualLoadTypesQuery,
    useGetAllChannelsQuery,
    useGetSearchedGatewayOrGroupQuery,
    useLazyGetAllChannelsQuery,
    useLazyGetLoadTrendsDataQuery,
    useUpdateDeviceTableMutation,
    useGetDeviceTableQuery,
    useGetDeviceByProfileQuery,
    useSendDeviceCommandMutation,
    useGetDevicePropertiesQuery,
    useGetChannelThresholdsQuery,
    useGetChannelDetailsMutation,
    useGetDeviceTrendsMutation,
    useGetUIMetadataMutation,
    useGetDeviceAlertCountQuery,
    useGetMasterDataMutation,
    useGetChannelDetailsWithCategoryQuery,
    useCreateDeviceMutation,
    useEditDeviceMutation,
    useGetDeviceByIdQuery,
    useGetDeviceTimelineQuery,
    useGetMobileDeviceTimelineQuery,
    useGenerateDeviceNameMutation,
    useGetThresholdQuery,
    useLazyGetThresholdQuery,
    useUpdateThresholdMutation,
    useUpdateDeviceMutation,
    useGetMobileVirtualLoadsQuery,
    useDeleteDeviceMutation,
    useGetDetailsOfLoadByIdQuery,
    useUpdateStatusOfLoadMutation,
    useUpdateLoadDetailsMutation,
    useLazyGetDeviceByIdQuery,
    useGetLoadListQuery,
    useExportTrendsMutation,
    useCreateVirtualLoadMutation,
    useGetVirtualLoadByIdQuery,
    useDeleteVirtualLoadByIdMutation,
    useUpdateVirtualLoadByIdMutation,
    useUpdateDeviceConfigMutation,
    useGetDeviceListMutation,
    useGetDeviceStatusQuery,
    useCreateScheduleMutation,
    useGetScheduleDetailsQuery,
    useDownloadChartDataMutation,
} = deviceApi;
