import React from 'react';

import { DevicesGrid } from './DevicesGrid';
import { ListSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import { setScheduleSelectedDeviceType } from '@fiji/common/src/features/schedule/schedulerSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { getOverlappingCount } from 'components/Schedule/common/utils/helpers';

export const DeviceTypesGrid = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const isLoading = useTypedSelector((state) => state['scheduler']['isLoading']);
    const dailySchedules = useTypedSelector((state) => state['scheduler']['dailyScheduledEvents']);
    const selectedDeviceType = useTypedSelector((state) => state['scheduler']['selectedDeviceType']);

    return (
        <Grid container direction="column">
            {isLoading ? (
                <ListSkeleton total={5} customSize={25} />
            ) : (
                dailySchedules?.map(({ deviceType, events }: any) => {
                    let stackHeight: any;
                    if (events?.length) {
                        events?.map((item: any) => {
                            const overlapCount = getOverlappingCount(item?.schedules);
                            stackHeight = Math.max(40, 40 * (overlapCount + 1));
                        });
                    }

                    return (
                        <>
                            <Grid
                                item
                                key={deviceType?.id}
                                className={selectedDeviceType?.id === deviceType?.id ? 'bg-light-blue' : ''}
                            >
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    style={{
                                        minHeight: stackHeight ?? 40,
                                        margin: 0,
                                    }}
                                    className={`border-right-1 ${
                                        selectedDeviceType?.id === deviceType?.id ? '' : 'border-bottom-1'
                                    } padding-left-16`}
                                >
                                    <Typography variant="body2">{deviceType?.name ?? '-'}</Typography>
                                    {selectedDeviceType?.id === deviceType?.id ? (
                                        <ExpandLess onClick={() => dispatch(setScheduleSelectedDeviceType(null))} />
                                    ) : (
                                        <ExpandMore
                                            onClick={() => {
                                                dispatch(setScheduleSelectedDeviceType(deviceType));
                                            }}
                                        />
                                    )}
                                </Stack>
                            </Grid>

                            {selectedDeviceType?.id === deviceType?.id && (
                                <DevicesGrid key={deviceType?.id} deviceTypeId={deviceType?.id} />
                            )}
                        </>
                    );
                })
            )}
        </Grid>
    );
};
