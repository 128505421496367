import { Grid, Stack } from '@mui/material';
import React from 'react';
import { createNumArray } from 'utils/helpers';
import { EventGrid } from './EventGrid';
import { useTypedSelector } from '@fiji/common';
import { getOverlappingCount, getOverlappingEvents } from '../common/utils/helpers';

export const DeviceRowGrid = React.memo(({ data }: { data: any }): JSX.Element => {
    const isGroupingEnabled = useTypedSelector((state) => state['schedule']['isGroupingEnabled']);
    return (
        Boolean(data?.length) &&
        data?.map(({ device, schedules: events }: any) => {
            const overlapCount = getOverlappingCount(events);
            const stackHeight = Math.max(40, 40 * (overlapCount + 1));
            const modifiedData = getOverlappingEvents(events);

            return (
                <Grid
                    container
                    key={device?.id}
                    sx={{ position: 'relative' }}
                    className={isGroupingEnabled ? 'bg-light-blue' : ''}
                >
                    {createNumArray(24)?.map((item) => (
                        <Grid item xs={0.5} key={item}>
                            <Stack
                                style={{
                                    display: 'flex',
                                    height: stackHeight,
                                    justifyContent: 'center',
                                    margin: 0,
                                    position: 'relative',
                                }}
                                className={`border-right-1 ${isGroupingEnabled ? '' : 'border-bottom-1'}`}
                            />
                        </Grid>
                    ))}
                    {events?.length &&
                        events?.map((schedule: any) => (
                            <EventGrid
                                key={schedule?.id}
                                event={modifiedData?.find((item: any) => item?.id === schedule?.id) ?? schedule}
                                eventsArr={events}
                            />
                        ))}
                </Grid>
            );
        })
    );
});
