import { Dayjs } from 'dayjs';

const getRequiredLabel = (index: any): string => {
    switch (index) {
        case 15:
            return '3';
        case 18:
            return '6';
        case 21:
            return '9';
        case 24:
            return '0';

        default:
            return String(index);
    }
};

const isPresentInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined): any => {
    if (dayB === null) {
        return false;
    }

    return dayA.isSame(dayB, 'week');
};

const getOverlappingCount = (events: any): any => {
    const arr: any[] = [];
    events?.forEach((event: any, index: any) => {
        const eventStart = new Date(event.schedules[0]);
        const eventEnd = new Date(event.actionEndTime);
        const eventStartSlot = eventStart.getHours() * 60;
        const eventEndSlot = eventEnd.getHours() * 60;

        const overlaps = events.filter((otherEvent: any, otherIndex: any) => {
            if (index === otherIndex) return false; // Skip the same event

            const otherEventStart = new Date(otherEvent.schedules[0]);
            const otherEventEnd = new Date(otherEvent.actionEndTime);
            const otherEventStartSlot = otherEventStart.getHours() * 60;
            const otherEventEndSlot = otherEventEnd.getHours() * 60;
            const threeH = 180;

            const bool =
                (otherEventStartSlot >= eventStartSlot && otherEventStartSlot <= eventStartSlot + threeH) ||
                (otherEventStartSlot >= eventStartSlot && otherEventStartSlot <= eventStartSlot - threeH) ||
                (otherEventStartSlot < eventEndSlot && otherEventEndSlot > eventStartSlot) || // Overlaps
                (otherEventStartSlot >= eventStartSlot && otherEventStartSlot <= eventEndSlot) || // Starts within
                (otherEventEndSlot >= eventStartSlot && otherEventEndSlot <= eventEndSlot);

            return bool;
        });

        if (overlaps.length && !arr.includes(overlaps.length)) {
            arr.push(overlaps.length);
        }
    });

    return arr.sort((a: number, b: number) => b - a)[0] ?? 0; // Each overlap is counted twice
};
const getOverlappingEvents = (events: any): any => {
    const arr: any[] = [];
    events?.forEach((event: any, index: any) => {
        const eventStart = new Date(event.schedules[0]);
        const eventEnd = new Date(event.actionEndTime);
        const eventStartSlot = eventStart.getHours() * 60;
        const eventEndSlot = eventEnd.getHours() * 60;

        const overlaps = events.filter((otherEvent: any, otherIndex: any) => {
            if (index === otherIndex) return false; // Skip the same event

            const otherEventStart = new Date(otherEvent.schedules[0]);
            const otherEventEnd = new Date(otherEvent.actionEndTime);
            const otherEventStartSlot = otherEventStart.getHours() * 60;
            const otherEventEndSlot = otherEventEnd.getHours() * 60;
            const threeH = 180;

            const bool =
                (otherEventStartSlot >= eventStartSlot && otherEventStartSlot <= eventStartSlot + threeH) ||
                (otherEventStartSlot >= eventStartSlot && otherEventStartSlot <= eventStartSlot - threeH) ||
                (otherEventStartSlot < eventEndSlot && otherEventEndSlot > eventStartSlot) || // Overlaps
                (otherEventStartSlot >= eventStartSlot && otherEventStartSlot <= eventEndSlot) || // Starts within
                (otherEventEndSlot >= eventStartSlot && otherEventEndSlot <= eventEndSlot);

            return bool;
        });
        const dData = arr?.find((item) => item?.id === event?.id);

        if (!dData) {
            if (overlaps?.length) {
                overlaps?.map((item: any, indexx: any) => {
                    arr.push({ ...item, top: 30 * (1 + indexx) });
                });
            }
        }
    });
    return arr;
};

export { getRequiredLabel, isPresentInSameWeek, getOverlappingCount, getOverlappingEvents };
