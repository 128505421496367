import React from 'react';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import {
    useCreateWidgetMutation,
    useDeleteWidgetMutation,
    useUpdateWidgetMutation,
} from '@fiji/common/src/features/widgetManagement/widgetApi';
import {
    resetCloneWidgetModal,
    resetRenameWidgetModal,
    setCloneWidgetPayload,
    setRenameWidgetPayload,
} from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useRBAC } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSaveAsCount } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { useConfigureWidgets } from 'pages/DashboardManagement';
import {
    handleCleanDefaultComponentPayload,
    handleCleanLoadsPayload,
    handleCleanMapPayload,
    handleCleanTimelineDeviceListAndGroupListPayload,
    handleCleanTrendsPayload,
} from '../utils/helpers';

type ReturnProps = {
    payload: any;
    isLoading: boolean;
    handleCloneModalAction: (...args: any) => void;
    handleCloneSave: (...args: any) => Promise<void>;
    handleDeleteModalAction: (...args: any) => void;
    handleDeleteConfirm: (...args: any) => Promise<void>;
    handleRenameModalAction: (...args: any) => void;
    handleRenameSave: (...args: any) => Promise<void>;
};
export const useHandleWidgetListActions = (
    selectorType: string,
    onClickCallback?: any,
    deleteModalClickCallback?: any
): ReturnProps => {
    const { t } = useTranslation();
    const location = useLocation();
    const modalPayload = useTypedSelector((state) => state.widget[selectorType]);
    const dispatch = useAppDispatch();

    const [createWidget, { isLoading }] = useCreateWidgetMutation();
    const [deleteWidget] = useDeleteWidgetMutation();
    const [updateWidget, { isLoading: updateWidgetLoader }] = useUpdateWidgetMutation();

    const navigate = useNavigate();

    const permissions = useTypedSelector(selectCurrentPermission);

    const saveAsCount = useTypedSelector((state) => state['widgetConfiguration']['saveAsCount']);

    const { hasPermission } = useRBAC(permissions);
    const canCreateUserWidgets = hasPermission('create-user-widgets');
    const canCreateSystemWidgets = hasPermission('create-system-widgets');

    const { widgetConfigHandler } = useConfigureWidgets(true);

    React.useEffect(() => {
        if (canCreateUserWidgets && !canCreateSystemWidgets) {
            dispatch(setCloneWidgetPayload({ key: 'isSystemWidget', value: false }));
        }
    }, [canCreateSystemWidgets, canCreateUserWidgets]);

    const handleCloneModalAction = (action: boolean, data: any): void => {
        if (action && data) {
            const presetPayload = {
                categoryId: data?.categoryId ?? '',
                ...(data?.config && { config: data?.config }),
                name: `${data?.name}-copy`,
                description: data?.description,
                isSystemWidget: data?.isSystemWidget,
                ...(data?.id && !data?.config && !data?.referenceWidgetId && { referenceWidgetId: data?.id }),
                ...(data?.referenceWidgetId && { referenceWidgetId: data?.referenceWidgetId }),
                sourceId: data?.sourceId,
                sourceName: data?.sourceName,
                type: 'PREDEFINED',
                widgetTypeId: data?.widgetType?.id,
            };
            onClickCallback(data);

            for (const key in presetPayload) {
                dispatch(setCloneWidgetPayload({ key: key, value: presetPayload[key] }));
            }
        }
    };

    const handleDeleteModalAction = (action: boolean, widgetData: any): any => {
        if (action) {
            onClickCallback(widgetData);
            return;
        }
        deleteModalClickCallback(widgetData);
    };

    const handleRenameModalAction = (action: boolean, data: any): void => {
        if (action && data) {
            onClickCallback(data);
            const presetPayload: any = {
                name: data?.name,
                description: data?.description,
            };
            for (const key in presetPayload) {
                dispatch(setRenameWidgetPayload({ key: key, value: presetPayload[key] }));
            }
        }
    };

    const handleCloneSave = async (modalType: any, onCancelCallback: any): Promise<void> => {
        const modalPayloadClone = JSON.parse(JSON.stringify(modalPayload.payload));
        if (canCreateUserWidgets && !canCreateSystemWidgets) {
            modalPayloadClone.isSystemWidget = false;
        }
        const configClone = JSON.parse(JSON.stringify(modalPayloadClone?.config ?? {}));

        if (modalType === 'save' && Object.keys(configClone)?.length) {
            switch (location?.state?.typeId) {
                case 'timeline':
                case 'device_list':
                case 'group_list':
                case 'schedule':
                    handleCleanTimelineDeviceListAndGroupListPayload(configClone);
                    break;
                case 'map':
                    handleCleanMapPayload(configClone);
                    break;

                case 'gauge':
                    delete configClone['data']['value'];
                    break;
                case 'properties':
                case 'settings':
                case 'details':
                    handleCleanDefaultComponentPayload(configClone);
                    break;
                case 'charts':
                    configClone['secondary']?.forEach((item: any) => {
                        delete item.value;
                    });
                    break;
                case 'trends':
                    handleCleanTrendsPayload(configClone);
                    break;
                case 'loads':
                case 'virtual_loads':
                    handleCleanLoadsPayload(configClone);
                    break;
                case 'score':
                case 'upcoming':
                case 'components':
                    handleCleanTimelineDeviceListAndGroupListPayload(configClone);
                    break;
                case 'energy_flow':
                    configClone['assets'] = configClone['assets']?.map((item: any) => ({
                        id: item.id,
                        deviceTypeId: item.deviceTypeId,
                        name: item.name,
                    }));
                    break;
                case 'metrics':
                case 'consumption_breakdown':
                case 'load_trends':
                    configClone['assets'] = configClone['assets']?.map((item: any) => ({
                        id: item?.id,
                        deviceTypeId: item?.deviceTypeId,
                        name: item?.name,
                    }));
                    break;
                case 'weather':
                    configClone['assets'] = {
                        parentId: configClone['assets']?.parentId,
                        id: configClone['assets']?.id,
                        name: configClone['assets']?.name,
                        lat: configClone['assets']?.lat,
                        lng: configClone['assets']?.lng,
                    };
                    break;
                default:
                    break;
            }

            if (location?.state?.typeId === 'timeline' || location?.state?.typeId === 'device_list') {
                delete configClone['secondary'];
                delete configClone['statusCount'];
            } else if (location?.state?.typeId === 'gauge') {
                delete configClone['data']['value'];
            }
            modalPayloadClone['config'] = configClone;
        }

        const data: any = await createWidget(modalPayloadClone);
        if (data?.data?.success) {
            dispatch(resetCloneWidgetModal());
            onCancelCallback();
            dispatch(
                setMessageContent({
                    isOpen: true,
                    ...(modalType === 'save'
                        ? {
                              message: `Changes to ${data?.data?.data?.name} ${data?.data?.data?.widgetType?.id} have been saved`,
                          }
                        : { message: 'Widget cloned successfully.' }),
                })
            );
            if (modalType === 'save') {
                const splittedArr = location.pathname.split('/');
                splittedArr[splittedArr?.length - 1] = data?.data?.data?.id;

                navigate(`${splittedArr?.join('/')}`, { state: { typeId: data?.data?.data?.widgetType?.id } });

                dispatch(setSaveAsCount(saveAsCount + 1));
            }
        }
    };
    const handleDeleteConfirm = async (data: any, deleteCancelCallback?: any): Promise<void> => {
        const { error }: any = await deleteWidget(data?.id);
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('WIDGETS:WIDGET_DELETE_SUCCESS', {
                        replace: { widgetName: data?.name },
                    }),
                })
            );
            deleteCancelCallback();
        }
    };

    const handleRenameSave = async (widgetData: any, cancelCallback: any): Promise<void> => {
        const payloadClone = JSON.parse(JSON.stringify(modalPayload?.payload ?? {}));
        if (payloadClone && !payloadClone?.description?.length) {
            payloadClone['description'] = null;
        }
        if (widgetData?.sourceId) {
            payloadClone['sourceId'] = widgetData?.sourceId;
            payloadClone['sourceName'] = widgetData?.sourceName;
        }
        const data: any = await updateWidget({ body: payloadClone, widgetId: widgetData?.id });
        if (data?.data?.success) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('WIDGETS:RENAME_WIDGET_MODAL.SUCCESS_MSG', {
                        replace: { widgetName: widgetData?.name },
                    }),
                })
            );
            widgetConfigHandler(widgetData?.id);
            dispatch(resetRenameWidgetModal());
            cancelCallback();
        }
    };

    return {
        payload: modalPayload?.payload,
        isLoading: isLoading || updateWidgetLoader,
        handleCloneSave,
        handleDeleteConfirm,
        handleRenameSave,
        handleRenameModalAction,
        handleCloneModalAction,
        handleDeleteModalAction,
    };
};
