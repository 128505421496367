import React from 'react';

import { EventGrid } from './EventGrid';
import { Grid, Stack } from '@mui/material';
import { RowSkeleton } from './RowSkeleton';
import { createNumArray } from 'utils/helpers';
import { useTypedSelector } from '@fiji/common';
import { DeviceRow } from './DeviceRow';
import { getOverlappingCount, getOverlappingEvents } from '../common/utils/helpers';

export const DeviceTypeRow = React.memo((): JSX.Element => {
    const isLoading = useTypedSelector((state) => state['schedule']['isLoading']);
    const schedules = useTypedSelector((state) => state['schedule']['schedules']);
    const selectedDeviceType = useTypedSelector((state) => state['schedule']['selectedDeviceType']);

    return (
        <>
            {isLoading ? (
                <RowSkeleton />
            ) : (
                schedules?.map(({ deviceType, events }: any) => {
                    let modifiedData: any;
                    let stackHeight: any;
                    events?.map((item: any) => {
                        const overlapCount = getOverlappingCount(item?.schedules);
                        stackHeight = Math.max(40, 40 * (overlapCount + 1));
                        modifiedData = getOverlappingEvents(item?.schedules);
                    });
                    return (
                        <>
                            <Grid
                                container
                                key={deviceType?.id}
                                sx={{ position: 'relative' }}
                                className={selectedDeviceType === deviceType?.id ? 'bg-light-blue' : ''}
                            >
                                {createNumArray(24)?.map((item) => (
                                    <Grid item xs={0.5} key={`deaf1saxag${item}`}>
                                        <Stack
                                            style={{
                                                minHeight: stackHeight ?? 40,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                margin: 0,
                                                position: 'relative',
                                            }}
                                            className={`border-right-1 ${
                                                selectedDeviceType === deviceType?.id ? '' : 'border-bottom-1'
                                            }`}
                                        />
                                    </Grid>
                                ))}

                                {Boolean(events?.length) &&
                                    selectedDeviceType !== deviceType?.id &&
                                    events?.map((event: any) =>
                                        event?.schedules?.map(
                                            (schedule: any, index: any, prev: any) =>
                                                prev[index - 1]?.id !== schedule?.id && (
                                                    <EventGrid
                                                        key={schedule?.id}
                                                        event={
                                                            modifiedData?.find(
                                                                (item: any) => item?.id === schedule?.id
                                                            ) ?? schedule
                                                        }
                                                        hideName
                                                    />
                                                )
                                        )
                                    )}
                            </Grid>
                            {selectedDeviceType === deviceType?.id && (
                                <DeviceRow key={deviceType?.id} drilledEvents={events} />
                            )}
                        </>
                    );
                })
            )}
        </>
    );
});
