import React from 'react';

import { Grid } from '@mui/material';
import { ListSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import { useTypedSelector } from '@fiji/common';
import { useHandleSchedule } from 'components/Schedule';
import { DevicesSubGrid } from './DevicesSubGrid';
import { getOverlappingCount } from 'components/Schedule/common/utils/helpers';

export const DevicesGrid = React.memo(({ deviceTypeId }: { deviceTypeId?: any }): JSX.Element => {
    const { groupingEnabled } = useHandleSchedule();

    const isLoading = useTypedSelector((state) => state['scheduler']['isLoading']);
    const dailySchedules = useTypedSelector((state) => state['scheduler']['dailyScheduledEvents']);

    const getDevices = (): any => {
        if (deviceTypeId) {
            return dailySchedules
                ?.find((schedule: any) => schedule?.deviceType?.id === deviceTypeId)
                ?.events?.map((event: any) => {
                    const overlapCount = getOverlappingCount(event?.schedules);
                    const stackHeight = Math.max(40, 40 * (overlapCount + 1));
                    return {
                        id: event?.device?.id,
                        name: event?.device?.name,
                        height: stackHeight,
                    };
                });
        }
        return dailySchedules?.map((device: any) => {
            const overlapCount = getOverlappingCount(device?.schedules);
            const stackHeight = Math.max(40, 40 * (overlapCount + 1));
            return {
                id: device?.device?.id,
                name: device?.device?.name,
                height: stackHeight,
            };
        });
    };

    return (
        <Grid container direction="column" className={groupingEnabled ? 'bg-light-blue' : ''}>
            {isLoading ? (
                <ListSkeleton total={5} customSize={20} />
            ) : (
                <DevicesSubGrid devices={getDevices()} isGrouped={groupingEnabled} />
            )}
        </Grid>
    );
});
