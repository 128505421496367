import { isNullOrUndefined } from '@fiji/common/src/utils/helpers';

function handlePrimaryHeading(widgetType: any): string {
    switch (widgetType) {
        case 'device_list':
        case 'schedule':
            return 'Selected Devices';
        case 'group_list':
            return 'Selected Groups';
        case 'loads':
            return 'Selected Loads';
        default:
            return 'Selected Groups and Devices';
    }
}

function handleSubHeading(widgetType: any, payload: any): string {
    switch (widgetType) {
        case 'schedule':
            return 'Select Devices that you would like to view in widget.';
        case 'device_list':
            return `Drag the Selected Devices in the order you would like to view them.Total ${
                payload?.selectedNodes?.length ?? 0
            } devices displayed in the widget can be modified in Display Preferences.`;
        case 'group_list':
            return `Drag the Selected Groups in the order you would like to view them. Total ${
                payload?.selectedNodes?.length ?? 0
            } groups displayed in the widget can be modified in Display Preferences`;
        case 'loads':
            return `Drag the Selected Loads in the order you would like to view them. Total ${
                payload?.selectedNodes?.length ?? 0
            } loads displayed in the widget can be modified in Display Preferences.`;
        case 'timeline':
        default:
            return `Drag the Selected Groups and Devices in the order you would like to view them.Total ${
                payload?.selectedNodes?.length ?? 0
            } devices and groups displayed in the widget can be modified in Display Preferences`;
    }
}

const getStringGroupPath = (pathName: any[], deviceName: any): string => {
    let path = '';
    if (Array.isArray(pathName)) {
        if (deviceName) path = `${path}${deviceName}`;
        if (pathName?.length > 0 && deviceName) path = `${path} <`;
        const groupPath: any = [...pathName];
        groupPath.forEach((groupName: string, index: number): void => {
            path = `${path} ${groupName}`;
            if (index !== groupPath?.length - 1) path = `${path} <`;
        });
    }
    return path;
};

const getChartMaxValue = (newData: any): any => {
    const sortedData = (a: any, b: any): any => {
        const aVal = a?.value ? +a.value : 0;
        const bVal = b?.value ? +b.value : 0;
        if (aVal > bVal) return -1;
        if (aVal < bVal) return 1;
        return 0;
    };
    return newData?.sort?.(sortedData)[0];
};

const getIntervalValue = (dataValue: any): any => {
    const value: number = getChartMaxValue(dataValue)?.value;
    if (value > 10) return Math.ceil(value / 10);
    return value ? (value / 12)?.toFixed?.(2) : undefined;
};

const isHighAndLow = (threshold: any): any => {
    const high = ['highCritical', 'highWarning'];
    const low = ['lowCritical', 'lowWarning'];
    const thresholdKeys = Object.keys(threshold ?? {});
    return high.find((key) => thresholdKeys?.includes(key)) && low.find((key) => thresholdKeys?.includes(key));
};

const findColor = (threshold: any, value: number): any => {
    const arrThreshold = Object.keys(threshold ?? {});
    const allMaxValue = arrThreshold
        .map((item: string) => ({
            name: item,
            value: threshold[item],
        }))
        .filter((item) => item?.value >= value);
    if (allMaxValue?.length < 1) {
        return '#55BF3B';
    }
    const highestValName = allMaxValue?.sort((a, b) => b?.value - a?.value)?.[0]?.name;
    if (highestValName === 'lowWarning' || highestValName === 'highWarning') {
        return '#DDDF0D';
    } else if (highestValName === 'lowCritical' || highestValName === 'highCritical') {
        return '#DF5353';
    }
    return '#55BF3B';
};

const generatePlotBands = (
    widgetData: any,
    isThreshold: boolean,
    isLowAndHigh?: boolean,
    isHorizontal?: boolean
): any => {
    const criticalColor = widgetData?.viewThreshold && isThreshold ? '#DF5353' : '#55BF3B';
    const warningColor = widgetData?.viewThreshold && isThreshold ? '#DDDF0D' : '#55BF3B';
    const highWarning = widgetData?.threshold?.highWarning;
    const highCritical = widgetData?.threshold?.highCritical;
    const lowWarning = widgetData?.threshold?.lowWarning;
    const lowCritical = widgetData?.threshold?.lowCritical;
    if (isHorizontal && !isLowAndHigh) {
        return [
            {
                from: 0,
                to: widgetData?.data?.value ?? 0,
                color: isThreshold ? findColor(widgetData?.threshold, widgetData?.data?.value) : '#55BF3B',
                thickness: 70,
            },
            {
                from: widgetData?.data?.value ?? 0,
                to: 9e9,
                color: '#eeeeee',
                thickness: 70,
            },
        ];
    }
    if (widgetData?.viewThreshold && isThreshold)
        return [
            ...(!isNullOrUndefined(lowCritical)
                ? [
                      {
                          from: 0,
                          to: lowCritical,
                          color: criticalColor,
                          thickness: 70,
                      },
                  ]
                : []),
            ...(!isNullOrUndefined(lowWarning)
                ? [
                      {
                          from: lowCritical,
                          to: lowWarning,
                          color: warningColor,
                          thickness: 70,
                      },
                  ]
                : []),
            {
                from: lowWarning ?? lowCritical ?? 0,
                to: highWarning ?? highCritical ?? 9e9,
                color: '#55BF3B',
                thickness: 70,
            },
            ...(!isNullOrUndefined(highWarning)
                ? [
                      {
                          from: highWarning,
                          to: highCritical || 9e9,
                          color: warningColor,
                          thickness: 70,
                      },
                  ]
                : []),
            ...(!isNullOrUndefined(highCritical)
                ? [
                      {
                          from: highCritical,
                          to: 9e9,
                          color: criticalColor,
                          thickness: 70,
                      },
                  ]
                : []),
        ];
    return [
        {
            from: 0,
            to: 9e9,
            color: '#55BF3B',
            thickness: 70,
        },
    ];
};

const handleCleanTimelineDeviceListAndGroupListPayload = (configClone: any): void => {
    if (!configClone['multiDevice']) {
        delete configClone['filters'];
    }
    if (Array.isArray(configClone?.preferences) && !configClone?.preferences?.length) {
        delete configClone['preferences'];
    }
    delete configClone['selectedNodes'];
    delete configClone['secondary'];
    delete configClone['statusCount'];
};

const handleCleanMapPayload = (configClone: any): void => {
    if (!configClone['multiDevice']) {
        delete configClone['filters'];
    }
    delete configClone['selectedNodes'];
    delete configClone['markers'];
};

const handleCleanDefaultComponentPayload = (configClone: any): void => {
    if (configClone['primary']) {
        configClone['primary']?.forEach((item: any) => {
            item?.channels?.forEach((subItem: any) => {
                delete subItem?.value;
            });
        });
    }
    if (configClone['secondary']) {
        configClone['secondary']?.forEach((item: any) => {
            item?.channels?.forEach((subItem: any) => {
                delete subItem?.value;
            });
        });
    }
};

const handleCleanTrendsPayload = (configClone: any): void => {
    configClone['secondary']?.forEach((item: any) => {
        delete item?.data;
    });
    if (configClone['trendsLegendData']) delete configClone['trendsLegendData'];
};

const handleCleanLoadsPayload = (configClone: any): void => {
    if (!configClone['multiDevice']) {
        delete configClone['selectedNodes'];
        configClone['secondary'] = configClone['secondary']?.map((item: any) => item.loadId);
    } else {
        configClone['selectedNodes'] = configClone['selectedNodes']?.map((item: any) => ({
            loadId: item.loadId,
            deviceId: item.deviceId,
        }));
        delete configClone['secondary'];
    }
};

export {
    handlePrimaryHeading,
    handleSubHeading,
    getStringGroupPath,
    generatePlotBands,
    getChartMaxValue,
    getIntervalValue,
    isHighAndLow,
    handleCleanDefaultComponentPayload,
    handleCleanLoadsPayload,
    handleCleanMapPayload,
    handleCleanTimelineDeviceListAndGroupListPayload,
    handleCleanTrendsPayload,
};
